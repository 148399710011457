import type { AlgoliaProduct } from '~/types/models/algoliaProduct';

export const useAlgoliaProduct = (product?: AlgoliaProduct) => {
    const { pathToSeoUrl } = useLanguageSelect();
    const { isLoggedIn } = useUser();
    const { getPrice } = useProductPrices();

    const listingBadges = computed<Array<'promotion' | 'remaining_stock' | 'archive' | 'bware' | 'new'>>(() => {
        const badges: any[] = [
            product?.isArchive && 'archive',
            isLoggedIn.value && product?.hasPromotion && 'promotion',
            isLoggedIn.value && product?.isRemainingStock && 'remaining_stock',
            isLoggedIn.value && product?.isBware && 'bware',
            product?.isNovelty && 'new',
        ];

        return badges.filter((badge) => badge).slice(0, 1);
    });

    const properties = computed(() => {
        return Object.entries(product?.attributes ?? {})?.filter(([_key, _value]) => {
            return !_key.startsWith('HEADLINE');
        });
    });

    const getUrl = (overrideProduct?: AlgoliaProduct): string | undefined => {
        const url = overrideProduct?.url || product?.url;

        return url ? pathToSeoUrl(url) : undefined;
    };

    const isProductBuyable = (product: AlgoliaProduct | null): boolean => {
        if (!product || !product.productNumber) return false;
        const price = getPrice(product.productNumber);
        return !product.isArchive && !price?.unbuyable;
    };

    return {
        listingBadges,
        properties,
        getUrl,
        isProductBuyable,
    };
};
